import { useEffect } from "react";

function Rubato() {
  useEffect(() => {
    window.location.replace(
      "https://pages.github.coecis.cornell.edu/glassboxgames/rubato-releases/"
    );
  }, []);
  return null;
}

export default Rubato;
