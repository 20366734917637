import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import benjaminshen from "./Components/benjaminshen";
import glozzpersonalitytest from "./Components/glozzpersonalitytest";
import rubato from "./Components/rubato";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/glozz" component={glozzpersonalitytest} />
        <Route exact path="/rubato" component={rubato} />

        <Route path="/" component={benjaminshen} />
      </Switch>
    </Router>
  );
}

export default App;
