import { useEffect } from "react";

function Benjaminshen() {
  useEffect(() => {
    window.location.replace("https://benjaminshen.com");
  }, []);
  return null;
}

export default Benjaminshen;
