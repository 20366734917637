import { useEffect } from "react";

function Glozzpersonalitytest() {
  useEffect(() => {
    window.location.replace("https://glozzpersonalitytest.herokuapp.com");
  }, []);
  return null;
}

export default Glozzpersonalitytest;
